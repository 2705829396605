import { motion } from "framer-motion";
import { Order, OrderStatus } from "../../../types";
import classNames from "classnames";
import { Typography } from "../../../ui/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const DINING_OPTIONS: Record<string, string> = {
  PICKUP: "Pickup",
  DELIVERY: "Delivery",
  DINE_IN: "Dine in",
  TAKE_OUT: "Take out",
  UNKNOWN: "Unknown",
} as const;

const OrderCard = ({ order }: { order: Order }) => {
  const { status } = order;

  const isReady = status === OrderStatus.ready;

  return (
    <motion.div
      key={order.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut" }}
      exit={{ opacity: 0 }}
      className={classNames(
        "flex justify-between items-center px-8 border border-gray-border rounded-xl flex-1",
        status === OrderStatus.ready
          ? "bg-surface-success"
          : "bg-surface-lighter",
      )}
    >
      <div className="overflow-hidden py-2">
        <Typography truncate variant="heading3" component="div">
          {order.customer_name}
        </Typography>
        <Typography
          variant="body1"
          className={classNames(
            "capitalize ",
            isReady ? "text-text-primary" : "text-text-secondary",
          )}
        >
          {DINING_OPTIONS[order.dining_option ?? "UNKNOWN"]}
        </Typography>
        <Typography variant="body1" truncate>
          {!isReady ? order.eta?.default_display : "Ready"}
        </Typography>
      </div>
      {isReady && (
        <div className="flex items-center">
          <FontAwesomeIcon
            className="w-10 h-10 text-icon-success rounded-full bg-text-white"
            icon={faCircleCheck}
          />
        </div>
      )}
    </motion.div>
  );
};

export default OrderCard;
