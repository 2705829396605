import classNames from "classnames";

const StatusBoardPage = ({
  children,
  isPortrait,
}: {
  children: React.ReactNode;
  isPortrait: boolean;
}) => {
  return (
    <div
      className={classNames(
        "flex bg-surface-lighter relative w-screen h-screen",
        isPortrait ? "flex-col" : "flex-row-reverse",
      )}
    >
      {children}
    </div>
  );
};

export default StatusBoardPage;
