import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { PasswordlessAuth } from "@localkitchens/passwordless-auth";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ApolloWrapper } from "./app/ApolloWrapper";
import reportWebVitals from "./reportWebVitals";

const authOrigin = process.env.REACT_APP_AUTH_ORIGIN || "";
const googleClientId = process.env.REACT_APP_GOOGLE_OATH_CLIENT_ID || "";

type TrackingEvent = {
  event: string;
  properties?: object;
};

type TrackState = {
  track: TrackingHandler;
};
type TrackingHandler = (trackingEvent: TrackingEvent) => void;

const track = () => {};
const identify = () => {};

const AppWrapper = () => {
  // Enables receiving a tracking handler from a child component. Tracking
  // requires data from graphql which will not be populated at
  // the time of rendering this component.
  const [tracker] = useState<TrackState>({ track });

  const trackingHandler = useMemo(() => tracker.track, [tracker]);

  useEffect(() => {
    if (
      !window.location.href.includes(".com") &&
      !window.location.href.includes(".kitchen") &&
      !window.location.href.includes(".dev")
    ) {
      const url = window.location.href.replace(".co", ".com");
      window.location.href = url;
    }
  }, []);

  return (
    <BrowserRouter>
      <PasswordlessAuth
        authOrigin={authOrigin}
        googleClientId={googleClientId}
        track={trackingHandler}
        identify={identify}
      >
        <ApolloWrapper>
          <App />
        </ApolloWrapper>
      </PasswordlessAuth>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type { TrackingHandler, TrackState, TrackingEvent };
