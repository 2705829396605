export const OrderStatus = {
  ready: "READY",
  cooking: "COOKING",
  created: "CREATED",
} as const;

export type OrderStatusType = typeof OrderStatus[keyof typeof OrderStatus];

export type Provider = {
  id: number;
  name: string;
  slug: string;
  revenue_center: string;
};

export type ETA = {
  base_eta: number;
  time_remaining: number;
  time_remaining_upper_bound: number;
  time_remaining_lower_bound: number;
  default_display: string;
};

export type Order = {
  id: number;
  status: OrderStatusType;
  provider?: Provider;
  customer_name?: string;
  is_pickup?: boolean;
  dining_option?: string;
  type: string;
  pickup_time: number;
  eta?: ETA;
};

export enum ScreenTypes {
  StatusScreen = 0,
  UpsellScreen = 1,
  VerticalUpsellScreen = 2,
}

export enum BoardOrientation {
  Portrait = 0,
  Landscape = 1,
}
