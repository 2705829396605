import { BoardOrientation } from "../types";
import useWindowSize from "./useWindowSize";

function useBoardOrientation(): BoardOrientation {
  const windowSize = useWindowSize();

  if ((windowSize.width ?? 0) < (windowSize.height ?? 0)) {
    return BoardOrientation.Portrait;
  }
  return BoardOrientation.Landscape;
}

export default useBoardOrientation;
