import { Typography } from "../../../ui/Typography";

type PageIndicatorProps = {
  currentPage: number;
  totalPages: number;
};

const PageIndicator = ({ currentPage, totalPages }: PageIndicatorProps) => {
  return (
    <div className="flex justify-center items-center center h-21">
      <Typography variant="body1">
        {totalPages > 0 && `${currentPage}/${totalPages}`}
      </Typography>
    </div>
  );
};

export default PageIndicator;
