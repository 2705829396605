import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Settings } from "./features/Settings";
import { useLocationAccount } from "./features/StatusBoard/hooks/useLocationAccount";
import { useAuth } from "@localkitchens/passwordless-auth";
import { StatusBoard } from "./features/StatusBoard/StatusBoard";

const App = () => {
  const [currentLocation, setCurrentLocation] = useState<string>("lafayette");
  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    undefined,
  );
  const { isAuthenticated, user, login, isLoading } = useAuth();

  const onLocationChange = (location: string) => {
    setCurrentLocation(location);
  };

  const { data: locationAccountData } = useLocationAccount(user?.email);

  useEffect(() => {
    let roles: string[] = [];
    if (user) {
      roles = user["https://app.localkitchens.co/roles"] || [];
    }

    if (roles.includes("STATUS_BOARD")) {
      const newSlug = locationAccountData?.location_account?.location?.slug;
      onLocationChange(newSlug);
    }
  }, [user, locationAccountData]);

  const routeLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(routeLocation.search);
    const loc = searchParams.get("location");

    if (loc) {
      onLocationChange(loc);
    }
  }, []);

  useEffect(() => {
    const setVersion = async () => {
      const res = await fetch("/version.txt");
      const version = await res.text();
      setCurrentVersion(version);
    };
    setVersion();
  }, []);

  useEffect(() => {
    if (currentVersion) {
      setInterval(async () => {
        const res = await fetch("/version.txt");
        const newVersion = await res.text();

        if (currentVersion !== newVersion) {
          (window as any).location.reload();
        }
      }, 30 * 1000);
    }
  }, [currentVersion]);

  useEffect(() => {
    setInterval(async () => {
      (window as any).location.reload();
    }, 6 * 60 * 60 * 1000); // every six hours refresh
  }, []);

  if (isLoading) {
    return (
      <div className="bg-lfg-light-gray h-screen">
        <div className="flex items-center justify-center h-full flex-col">
          <div className="mb-24">Loading...</div>
        </div>
        <img
          className="w-full absolute bottom-0"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf_alt.png"
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="bg-lfg-light-gray h-screen">
        <div className="flex items-center justify-center h-full flex-col">
          <button
            className="z-50 mb-24 bg-lfg-primary text-lfg-light-gray font-lfg-semibold uppercase px-12 py-6 rounded"
            onClick={() => login()}
          >
            Login
          </button>
        </div>
        <img
          className="w-full absolute bottom-0"
          src="https://lfg-landing.s3-us-west-2.amazonaws.com/sf_alt.png"
        />
      </div>
    );
  }

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <StatusBoard currentLocation={currentLocation} />
        </Route>
        <Route exact path="/settings">
          <Settings />
        </Route>
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default App;
