const BannerPhone = () => {
  return (
    <div className="relative w-full h-full max-w-[581px]">
      <img
        src="/images/app_screenshot.png"
        alt="Iphone frame"
        className="absolute bottom-0"
      />

      <div
        className="
          absolute 
          bottom-[62px] 
          left-[76px] 
          rounded-[14px] 
          overflow-hidden 
          border 
          border-black 
          h-full 
          w-full
          max-w-[412px] 
          max-h-[76px]
        "
      >
        <img
          src="/assets/reward-confetti.webp"
          alt="Confetti"
          className="absolute"
        />
        <div className="absolute z-1 flex items-center justify-between w-full h-full text-[16px] p-[18px]">
          <div className="bg-black text-white py-2 px-4 rounded-xl">
            $10 Reward
          </div>
          <p className="font-semibold">Apply</p>
        </div>
      </div>
    </div>
  );
};

export default BannerPhone;
