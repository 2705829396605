import { Typography } from "../../../ui/Typography";
import BannerPhone from "./BannerPhone";

const Banner = ({ isPortrait }: { isPortrait: boolean }) => {
  if (isPortrait) {
    return (
      <div className="bg-surface-light pl-12 pr-[5px] pt-12 flex justify-between items-start">
        <div className="flex flex-col pb-[24px]">
          <Typography variant="title2" className="w-[390px] ml-6">
            $10 off your first online order.
          </Typography>
          <img
            src="/images/qr.png"
            alt="Upsell QR code to promote downloading the app."
            className="w-[224px]"
          />
        </div>

        <BannerPhone />
      </div>
    );
  }

  return (
    <div className="bg-surface-light pt-24 flex flex-col items-center min-w-[609px]">
      <img
        src="/images/qr.png"
        alt="Upsell QR code to promote downloading the app."
        className="w-[224px] mb-4"
      />
      <Typography variant="title2" className="text-center w-[500px]">
        $10 off your first online order.
      </Typography>

      <BannerPhone />
    </div>
  );
};

export default Banner;
