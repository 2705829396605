import { Typography } from "../../../ui/Typography";
import BannerPhoneEmpty from "./BannerPhoneEmpty";

const BannerEmpty = ({ isPortrait }: { isPortrait: boolean }) => {
  if (isPortrait) {
    return (
      <div className="bg-surface-light h-[100vh] flex flex-col justify-center items-center px-24">
        <BannerPhoneEmpty />

        <Typography variant="title2" className="mt-6 text-center">
          $10 off your first online order.
        </Typography>

        <img
          src="/images/qr.png"
          alt="Upsell QR code to promote downloading the app."
          className="w-[408px] mt-10"
        />
      </div>
    );
  }

  return (
    <div className="bg-surface-light flex h-[100vh] px-28 items-center">
      <BannerPhoneEmpty />

      <div className="flex flex-col justify-center w-full">
        <Typography variant="title2" className="ml-8 mb-10">
          $10 off your first online order.
        </Typography>
        <img
          src="/images/qr.png"
          alt="Upsell QR code to promote downloading the app."
          className="w-[396px]"
        />
      </div>
    </div>
  );
};

export default BannerEmpty;
