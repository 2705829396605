import { useAuth } from "@localkitchens/passwordless-auth";

export const Settings = () => {
  const { logout } = useAuth();

  return (
    <div>
      <button onClick={() => logout()}>Log out</button>
    </div>
  );
};
