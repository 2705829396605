import classNames from "classnames";
import Banner from "./Banner";
import PageIndicator from "./PageIndicator";

const StatusBoardOrders = ({
  numPages,
  currentPageNum,
  children,
  isPortrait,
}: {
  numPages: number;
  currentPageNum: number;
  children?: React.ReactNode;
  isPortrait: boolean;
}) => {
  return (
    <>
      <div className="flex flex-col grow">
        <PageIndicator currentPage={currentPageNum + 1} totalPages={numPages} />

        <div
          className={classNames(
            "gap-8 p-8 pt-0 grow",
            isPortrait
              ? "grid grid-cols-3 grid-rows-6"
              : "grid grid-cols-4 grid-rows-4",
          )}
        >
          {children}
        </div>
      </div>
      <Banner isPortrait={isPortrait} />
    </>
  );
};

export default StatusBoardOrders;
