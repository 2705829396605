type StatusBoardFooterProps = {
  handleLogout: () => void;
};

function StatusBoardFooter({ handleLogout }: StatusBoardFooterProps) {
  return (
    <div>
      <button
        onClick={handleLogout}
        className="text-transparent absolute bottom-0 right-0 px-2 py-3"
      >
        Log out
      </button>
    </div>
  );
}

export default StatusBoardFooter;
