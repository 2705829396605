import { gql, useQuery } from "@apollo/client";
import { Order } from "../../../types";

const STATUS_BOARD_STORE_ORDERS_QUERY = gql`
  query STATUS_BOARD_STORE_ORDERS_QUERY($location_slug: String) {
    orders(location_slug: $location_slug) {
      id
      status
      provider {
        id
        name
        slug
        revenue_center
      }
      customer_name
      is_pickup
      dining_option
      type
      pickup_time
      eta {
        default_display
        base_eta
        time_remaining
        time_remaining_lower_bound
        time_remaining_upper_bound
      }
    }
  }
`;

export const useStoreOrders = (locationSlug: String) => {
  const { data } = useQuery(STATUS_BOARD_STORE_ORDERS_QUERY, {
    variables: { location_slug: locationSlug },
    pollInterval: 10 * 1000,
    skip: !locationSlug,
  });

  const orders = data?.orders || [];
  const cooking = cookingOrders(orders);
  const ready = readyOrders(orders);

  const allOrders = ready.concat(cooking);

  return {
    orders: allOrders,
  };
};

const cookingOrders = (orders: Order[]) =>
  orders.filter(
    (order: Order) =>
      (order.status === "COOKING" || order.status === "CREATED") &&
      order.type !== "EMPLOYEE",
  );

const readyOrders = (orders: Order[]) =>
  orders
    .filter(
      (order: Order) => order.status === "READY" && order.type !== "EMPLOYEE",
    )
    .sort((a: any, b: any) => a.cooking_at - b.cooking_at);
