const BannerPhoneEmpty = () => {
  return (
    <div className="relative flex justify-center w-full h-full max-w-[513px] max-h-[1011px] ">
      <img src="/images/full_app_screenshot.png" alt="Iphone frame" />

      <div
        className="
          absolute 
          top-[37.7%] 
          left-[50%] 
          translate-x-[-50%] 
          translate-y-[-50%] 
          rounded-[14px] 
          overflow-hidden 
          border 
          border-black 
          h-full 
          w-full
          max-w-[354px] 
          max-h-[70px]
        "
      >
        <img
          src="/assets/reward-confetti.webp"
          alt="Confetti"
          className="absolute"
        />
        <div className="absolute z-1 flex items-center justify-between w-full h-full text-[16px] p-[18px]">
          <div className="bg-black text-white py-2 px-4 rounded-xl">
            $10 Reward
          </div>
          <p className="font-semibold">Apply</p>
        </div>
      </div>
    </div>
  );
};

export default BannerPhoneEmpty;
