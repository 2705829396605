export function chunkify(
  array: Array<any>,
  chunkSize: number,
): Array<Array<any>> {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}
